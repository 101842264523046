<template>
  <!-- v-touch="{
      left: () => $router.go(1),
      right: () => $router.push(-1)
    }" -->
  <v-row align="center">
    <v-col>
      <app-card>
        <v-tabs
          v-model="tab"
          background-color="#1E1E1E"
          centered
          dark
          icons-and-text
          grow
          @change="updateTag"
        >
          <v-tabs-slider />

          <v-tab
            href="#tab-1"
          >
            {{ $t("message.howsinger_directory_tab1") }}
            <br>
            {{ $t("message.howsinger_directory_tab2") }}
            <v-icon>mdi-account</v-icon>
          </v-tab>

          <v-tab
            href="#tab-2"
          >
            {{ $t("message.concept_tweets_tab1") }}
            <br>
            {{ $t("message.concept_tweets_tab2") }}
            <v-icon>mdi-table-furniture</v-icon>
          </v-tab>

          <v-tab
            href="#tab-4"
          >
            {{ $t("message.commission_tab1_tool") }}
            <br>
            {{ $t("message.commission_tab2_tool") }}
            <v-icon>mdi-home-account</v-icon>
          </v-tab>

          <!-- <v-tab href="#tab-3">
            {{ $t("message.technique_tweets") }}
            <v-icon>mdi-account-box</v-icon>
          </v-tab> -->
        </v-tabs>

        <v-tabs-items
          v-model="tab"
        >
          <v-tab-item
            v-for="i in 2"
            :key="i"
            :value="'tab-'+i"
          >
            <v-card flat>
              <v-card-text>
                <h4>{{ $t("message.help_9_1") }}</h4>
                <a
                  href="https://twitter.com/ff14housingeden"
                >   <v-icon
                  color="#55acee"
                >
                  mdi-twitter
                </v-icon>
                  @ff14housingeden
                </a><br>
              </v-card-text>
              <v-divider class="mx-0" />
              <v-card-title>
                <h2>{{ $t("message.tool_13") }}</h2>
              </v-card-title>
              <v-card-text>
                <h4>{{ $t("message.tool_15") }}</h4>
              </v-card-text>
              <v-chip
                v-clipboard:copy="tag_copy"
                v-clipboard:success="onCopy"
                v-clipboard:error="onError"
                dark
                class="ma-2"
                label
                text-color="primary"
                color="primary"
                outlined
              >
                <v-icon left>
                  mdi-content-copy
                </v-icon>
                {{ tag_copy }}
              </v-chip>

              <v-divider class="mx-0" />

              <v-card-title>
                <h2>{{ $t("message.tool_14") }}</h2>
              </v-card-title>
              <v-card-text>
                <h4>
                  {{ $t("message.tool_16") }}<br>
                  {{ $t("message.tool_17") }}<br>
                </h4>
              </v-card-text>
              <v-chip
                v-clipboard:copy="tag_copy"
                v-clipboard:success="onCopy"
                v-clipboard:error="onError"
                dark
                class="ma-2"
                label
                text-color="primary"
                color="primary"
                outlined
              >
                <v-icon left>
                  mdi-content-copy
                </v-icon>
                {{ tag_copy }}
              </v-chip>
              <v-divider class="mx-0" />

              <v-card-title>
                <h2>{{ $t("message.tool_1") }}</h2>
              </v-card-title>

              <v-card-text>
                <h4>
                  {{ $t("message.tool_2") }}<br>
                  {{ $t("message.tool_3") }}<br>
                  {{ $t("message.tool_4") }}<br>
                  {{ $t("message.tool_5") }}<br>
                  {{ $t("message.tool_5_1") }}<br>
                </h4>
              </v-card-text>
              <v-card-actions>
                <v-text-field
                  v-model="message"
                  color="#00acee"
                  :rules="rules"
                  :append-outer-icon="
                    marker ? 'mdi-twitter' : 'mdi-twitter'
                  "
                  filled
                  clear-icon="mdi-close-circle"
                  clearable
                  type="text"
                  placeholder="ex. 1363737140731908097"
                  @click:append="toggleMarker"
                  @click:append-outer="sendMessage"
                  @click:clear="clearMessage"
                />
              </v-card-actions>
              <v-divider class="mx-0" />
              <v-card-title>
                <h2>{{ $t("message.tool_6") }}</h2>
              </v-card-title>

              <v-card-text>
                <h4>
                  {{ $t("message.tool_7") }}<br>
                  {{ $t("message.tool_8") }}<br>
                  {{ $t("message.tool_9") }}<br>
                  {{ $t("message.tool_10") }}<br>
                </h4>
              </v-card-text>
              <v-card-actions>
                <v-text-field
                  v-model="message_del"
                  color="#00acee"
                  :rules="rules"
                  :append-outer-icon="
                    marker ? 'mdi-twitter' : 'mdi-twitter'
                  "
                  filled
                  clear-icon="mdi-close-circle"
                  clearable
                  type="text"
                  placeholder="ex. 1363737140731908097"
                  @click:append="toggleMarker"
                  @click:append-outer="sendMessageDel"
                  @click:clear="clearMessageDel"
                />
              </v-card-actions>
              <v-divider class="mx-0" />
              <v-card-title>
                <h2>{{ $t("message.tool_11") }}</h2>
              </v-card-title>
              <v-card-text>
                <h4>
                  {{ $t("message.tool_12") }}
                </h4>
              </v-card-text>
              <v-img
                src="https://e-d-e-n.site/tool_01.jpg"
                max-height="600"
                max-width="600"
              />
            </v-card>
          </v-tab-item>
          <v-tab-item
            :value="'tab-4'"
          >
            <v-card flat>
              <v-card-title>
                <h2>{{ $t("message.tool_18") }}</h2>
              </v-card-title>
              <v-card-text>
                <h4>
                  {{ $t("message.tool_21") }}<br>
                </h4>
              </v-card-text>
              <CommissionOpen />
              <v-divider class="mx-0" />

              <v-card-title>
                <h2>{{ $t("message.tool_19") }}</h2>
              </v-card-title>
              <v-card-text>
                <h4>
                  {{ $t("message.tool_22") }}<br>
                  {{ $t("message.tool_17") }}<br>
                </h4>
              </v-card-text>
              <CopyCommissionOpen />
              <v-divider class="mx-0" />

              <v-card-title>
                <h2>{{ $t("message.tool_20") }}</h2>
              </v-card-title>
              <v-card-text>
                <h4>
                  {{ $t("message.tool_23") }}<br>
                </h4>
              </v-card-text>
              <CommissionClosed />
              <v-divider class="mx-0" />

              <v-card-title>
                <h2>{{ $t("message.tool_25") }}</h2>
              </v-card-title>
              <v-card-text>
                <h4>
                  {{ $t("message.tool_24") }}<br>
                </h4>
              </v-card-text>
              <CommissionDel />
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </app-card>
    </v-col>
  </v-row>
</template>

<script>
  export default {
    name: 'ToolView',
    metaInfo: {
      title: 'Tool',
      titleTemplate: '%s | FFXIV Housing Eden',
      meta: [
        { charset: 'utf-8' },
        {
          name: 'viewport',
          content: 'width=device-width, initial-scale=1',
        },
        {
          name: 'keywords',
          content:
            'ff14 ハウジング,ハウジング ff14,ff14 housing,housing ff14,ff14ハウジング,ff14housing,ハウジング,ハウジングエデン,ff14,ファイナルファンタジー,housing,ffxiv,final fantasy,Housing Eden,housing eden,ff14 ハウジング,ffxiv housing',
        },
        { property: 'og:type', content: 'website' },
        { name: 'twitter:card', content: 'summary_large_image' },
        { property: 'og:url', content: 'https://e-d-e-n.site' },
        { property: 'og:title', content: 'FFXIV Housing Eden' },
        {
          name: 'description',
          content:
            'FFXIV Housing EdenはFFXIV(ファイナルファンタジー14)でのハウジング情報を配信するサイトです。',
        },
        {
          property: 'og:image',
          content: 'https://e-d-e-n.site/twitter_card.jpg?3',
        },
      ],
    },
    data: () => ({
      rules: [
        (value) => (value || '').length >= 1 || 'Please enter your Tweet ID or Tweet URL',
        (value) => {
          const pattern = /[0-9]/
          return pattern.test(value) || 'Please enter your Tweet ID or Tweet URL'
        },
      ],
      message: '',
      message_del: '',
      tweetid: '',
      marker: true,
      tab: '',
      tag: '',
      tag_copy: '',
    }),
    created: async function () {
      this.tab = `${this.$route.params.tabid}`
      this.updateTag()
    },
    methods: {
      toggleMarker () {
        this.marker = !this.marker
      },
      sendMessage () {
        // console.log(this.message)
        if (isNaN(this.message)) {
          this.tweetid = this.message.match('status/.*')[0]
          // console.log(this.tweetid)
          this.tweetid = this.tweetid.match('[0-9]{1,}')[0]
          // console.log(this.tweetid)
        } else { this.tweetid = this.message }

        window.open(
          'https://twitter.com/intent/tweet?text=@tos ' +
            this.tweetid +
            '&hashtags=' +
            this.tag +
            '_add',
          '_blank',
        )
      },

      updateTag () {
        // console.log(event)
        this.path = '/#/components/tool/' + this.tab
        history.replaceState('', '', this.path)

        switch (this.tab) {
          case 'tab-1':
            this.tag = 'HousingEden'
            this.tag_copy = '#HousingEden'
            break
          case 'tab-2':
            this.tag = 'HousingEdenConcept'
            this.tag_copy = '#HousingEdenConcept'
            break
          case 'tab-3':
            this.tag = 'HousingEdenTechnique'
            this.tag_copy = '#HousingEdenTechnique'
            break
          case 'tab-4':
            this.tag = 'HousingEdenCommissionOpen'
            this.tag_copy = '#HousingEdenCommissionOpen'
            break
        }
      },
      sendMessageDel () {
        // console.log(this.message_del)
        if (isNaN(this.message_del)) {
          this.tweetid = this.message_del.match('status/.*')[0]
          // console.log(this.tweetid)
          this.tweetid = this.tweetid.match('[0-9]{1,}')[0]
          // console.log(this.tweetid)
        } else { this.tweetid = this.message_del }

        // console.log(this.tab)
        switch (this.tab) {
          case 'tab-1':
            this.tag = 'HousingEden'
            break
          case 'tab-2':
            this.tag = 'HousingEdenConcept'
            break
          case 'tab-3':
            this.tag = 'HousingEdenTechnique'
            break
          case 'tab-4':
            this.tag = 'HousingEdenCommissionOpen'
            break
        }
        window.open(
          'https://twitter.com/intent/tweet?text=@tos ' +
            this.tweetid +
            '&hashtags=' +
            this.tag +
            '_del',
          '_blank',
        )
      },
      clearMessage () {
        this.message = ''
      },
      clearMessageDel () {
        this.message_del = ''
      },
      resetIcon () {
        this.iconIndex = 0
      },
      changeIcon () {
        this.iconIndex === this.icons.length - 1
          ? this.iconIndex = 0
          : this.iconIndex++
      },
      onCopy: function (e) {
        alert('Copied')
        console.log('You just copied: ' + e.text)
      },
      onError: function (e) {
        console.log('Failed to copy texts')
      },
    },
  }
</script>
